<template>
  <v-container class="pt-6 px-0">
    <v-stepper flat v-model="stepIndex" v-if="stepIndex > 0">
      <!-- 회원가입 Stepper Header -->
      <v-stepper-header>
        <v-stepper-step :complete="stepIndex > 0" step="">
          회원 가입
        </v-stepper-step>
        <v-stepper-step :complete="stepIndex > 1" step="">
          정보 입력
        </v-stepper-step>
        <v-stepper-step :complete="stepIndex > 2" step="">
          추가 정보
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <!-- Step.1 회원가입 -->
        <v-stepper-content step="1" class="px-7 pt-8">
          <v-card v-if="loginLoading" flat class="text-center pt-15">
            <v-progress-circular color="primary" indeterminate>
            </v-progress-circular>
            <v-card-title class="justify-center font-weight-bold title">
              잠시만 기다려주세요.
            </v-card-title>
          </v-card>
          <v-card v-else flat color="transparent" class="mx-auto">
            <v-form ref="form1" lazy-validation>
              <!-- SNS 간편가입 -->
              <v-card-actions class="justify-center pa-0">
                <span
                  class="text-center headline-1 font-weight-bold pr-4 mx-0"
                  style="color: #767579"
                >
                  SNS 계정으로 간편가입
                </span>
                <!-- signInWithGoogle -->
                <v-btn
                  @click="signInWithGoogle"
                  depressed
                  icon
                  class="pa-0 mr-1"
                >
                  <v-avatar size="30" color="surface">
                    <v-img :src="require('@/assets/logos/auth/google.png')" />
                  </v-avatar>
                </v-btn>
                <!-- signInWithKakao -->
                <v-btn
                  @click="signInWithKakao"
                  icon
                  depressed
                  class="pa-0 mx-1"
                >
                  <v-avatar size="30" color="#fee500">
                    <v-img :src="require('@/assets/logos/auth/kakao.png')" />
                  </v-avatar>
                </v-btn>
                <!-- signInWithNaver -->
                <v-btn
                  @click="signInWithNaver"
                  icon
                  depressed
                  class="pa-0 mx-1"
                >
                  <v-avatar size="30" color="#00c93b">
                    <v-img :src="require('@/assets/logos/auth/naver.png')" />
                  </v-avatar>
                </v-btn>
              </v-card-actions>

              <v-card-actions class="pa-0 pt-2">
                <v-divider></v-divider>
                <v-subheader>or</v-subheader>
                <v-divider></v-divider>
              </v-card-actions>

              <!-- 일반 회원가입 -->
              <v-card-text class="pa-2 pt-10">
                <v-text-field
                  class="pb-3 ma-0"
                  label="이메일을 입력해주세요"
                  v-model="form.email"
                  :rules="[rule.required, rule.maxLength(50), rule.email]"
                  required
                  single-line
                  flat
                  clearable
                  dense
                />
                <v-text-field
                  class="pb-3 ma-0"
                  v-model="form.password1"
                  label="비밀번호를 입력해주세요"
                  :append-icon="form.show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="form.show1 = !form.show1"
                  style="ime-mode: disabled"
                  :rules="[
                    rule.required,
                    rule.minLength(8),
                    rule.maxLength(50),
                  ]"
                  :type="form.show1 ? 'text' : 'password'"
                  required
                  flat
                  clearable
                  dense
                  single-line
                >
                </v-text-field>
                <v-text-field
                  class="pb-3 ma-0"
                  v-model="form.password2"
                  label="비밀번호를 다시 입력해주세요"
                  :append-icon="form.show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="form.show2 = !form.show2"
                  style="ime-mode: disabled"
                  :rules="[
                    form.password1 === form.password2 ||
                      '비밀번호가 일치하지 않습니다.',
                  ]"
                  :type="form.show2 ? 'text' : 'password'"
                  required
                  flat
                  clearable
                  dense
                  single-line
                >
                </v-text-field>
                <v-btn
                  color="primary"
                  large
                  depressed
                  class="font-weight-bold body-2 font-weight-bold mt-5"
                  block
                  @click="createWithEmailAndPassword"
                >
                  이메일로 회원가입
                </v-btn>
              </v-card-text>

              <!-- 기존 계정이 있는 경우 -->
              <v-card-actions class="justify-center">
                <div class="text-center subtitle-2">
                  계정이 있으신가요?
                  <a class="primary--text" @click="$router.push('/login')">
                    로그인
                  </a>
                </div>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-stepper-content>

        <!-- Step.2 정보 입력 -->
        <v-stepper-content step="2" class="px-12 pt-14">
          <!-- 프로필 이미지 -->
          <v-avatar
            size="100"
            :color="form.imgUpload ? 'rgb(189,189,189,0.5)' : 'surface'"
            class="mx-auto d-flex mb-12"
            @mouseover="form.imgUpload = true"
            @mouseleave="form.imgUpload = false"
          >
            <v-img
              :src="form.imgUploadFile ? form.imgUploadURL : form.photoURL"
              style="border: 3px solid #c5c5ca"
              :style="form.imgUpload ? 'opacity: 0.8' : ''"
            >
              <v-btn
                v-if="form.imgUpload"
                x-small
                class="ma-auto"
                @click="$refs.uploader.click()"
              >
                업로드
              </v-btn>
              <input
                ref="uploader"
                class="d-none"
                type="file"
                @change="onFileChanged"
              />
            </v-img>
          </v-avatar>
          <!-- 성함, 연락처 -->
          <v-card flat>
            <v-form ref="form2" lazy-validation>
              <v-card-text class="pa-0">
                <v-row
                  style="border-bottom: 1px solid #909094; height: 40px"
                  class="ma-0"
                >
                  <v-col cols="9" class="pa-0" align-self="center">
                    <span>{{ form.email }}</span>
                  </v-col>
                  <v-col cols="3" class="pa-0 text-right" align-self="center">
                    <v-btn
                      v-if="form.emailVerified == false"
                      class="px-1"
                      small
                      :loading="emailCheckLoading"
                      @click="checkEmailVerified()"
                      color="primary"
                    >
                      인증 확인
                    </v-btn>
                    <!-- 카카오계정 -->
                    <v-avatar
                      v-else-if="
                        this.$store.getters['auth/user/GET_USER'].uid.split(
                          ':',
                        )[0] == 'kakao'
                      "
                      size="28"
                      color="#fee500"
                    >
                      <v-img :src="require('@/assets/logos/auth/kakao.png')" />
                    </v-avatar>
                    <!-- 네이버계정 -->
                    <v-avatar
                      v-else-if="
                        this.$store.getters['auth/user/GET_USER'].uid.split(
                          ':',
                        )[0] == 'naver'
                      "
                      size="28"
                      color="#00c93b"
                    >
                      <v-img :src="require('@/assets/logos/auth/naver.png')" />
                    </v-avatar>
                    <!-- 구글계정 -->
                    <v-avatar
                      v-else-if="
                        this.$store.getters['auth/user/GET_USER']
                          .providerData[0].providerId == 'google.com'
                      "
                      size="28"
                      color="surface"
                    >
                      <v-img :src="require('@/assets/logos/auth/google.png')" />
                    </v-avatar>
                    <v-icon v-else color="primary" class="mr-2">
                      mdi-check
                    </v-icon>
                  </v-col>
                </v-row>
                <div class="d-flex pt-0" v-if="emailAgain">
                  <div class="ml-auto mr-2 caption" style="height: 20px">
                    메일이 오지 않았나요?
                  </div>
                  <v-btn
                    text
                    style="height: 20px"
                    x-small
                    class="primary--text caption pa-0"
                    @click="sendEmail()"
                  >
                    재발송
                  </v-btn>
                </div>

                <v-row>
                  <v-col cols="12" class="pt-5 pb-0">
                    <v-text-field
                      class="pa-0 ma-0"
                      label="이름을 입력해주세요"
                      v-model="form.displayName"
                      :rules="[rule.required, rule.alpha]"
                      required
                      single-line
                      flat
                      dense
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pt-5">
                    <v-text-field
                      class="pa-0 ma-0"
                      label="연락처 '-'를 제외하고 입력해주세요"
                      v-model="form.phoneNumber"
                      :rules="[
                        rule.required,
                        rule.number,
                        rule.maxLength(11),
                        rule.minLength(11),
                      ]"
                      required
                      single-line
                      flat
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="mt-7 d-flex justify-center">
                <v-btn
                  class="mx-2 px-5"
                  color="surface"
                  @click="deleteUserDialog = true"
                >
                  취소
                </v-btn>
                <v-dialog v-model="deleteUserDialog" width="350px">
                  <v-card rounded="lg" class="py-3">
                    <v-card-title class="d-flex justify-center">
                      가입을 취소하시겠습니까?
                    </v-card-title>
                    <v-card-text class="d-flex justify-center">
                      가입 취소 시, 해당 계정의 모든 정보가 삭제됩니다.
                    </v-card-text>
                    <v-card-actions class="mb-2 d-flex justify-center">
                      <v-btn class="mx-2" @click="deleteUserDialog = false">
                        취소
                      </v-btn>
                      <v-btn
                        class="mx-2"
                        @click="deleteUser()"
                        :loading="abortBtnLoading"
                        color="error"
                      >
                        확인
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-btn
                  class="mx-2 px-5"
                  color="primary"
                  @click="save1()"
                  :loading="save1BtnLoading"
                >
                  다음
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-stepper-content>

        <!-- Step.3 추가 정보 -->
        <v-stepper-content step="3" class="pt-6 px-1">
          <v-card flat>
            <!-- 개인 학생 기업 탭 헤더 -->
            <v-tabs
              v-model="form.userClass"
              centered
              fixed-tabs
              class="px-0"
              color="black"
            >
              <v-tab>
                <v-icon class="mr-1"> mdi-account-circle </v-icon> 개인회원
              </v-tab>
              <v-tab>
                <v-icon class="mr-1"> mdi-account-school </v-icon> 학생회원
              </v-tab>
              <v-tab>
                <v-icon class="mr-1"> mdi-office-building </v-icon> 기업회원
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="form.userClass">
              <!-- 개인 -->
              <v-tab-item>
                <div
                  class="d-flex mx-auto mt-7"
                  style="height: 96px; width: 85px"
                >
                  <v-img
                    class="align-center"
                    :src="require('@/assets/logos/madeall_icon.png')"
                  />
                </div>
              </v-tab-item>
              <!-- 학생 -->
              <v-tab-item class="px-11">
                <div class="d-flex my-8">
                  <v-autocomplete
                    v-model="form.schoolInfo.school"
                    :items="schoolList"
                    cache-items
                    single-line
                    hide-no-data
                    hide-details
                    clearable
                    label="학교를 선택해주세요"
                  />
                </div>
                <div class="d-flex my-1 pt-0">
                  <v-text-field
                    v-model="form.schoolInfo.studentId"
                    label="학번을 입력해주세요"
                    class="onSurface--text"
                    flat
                    clearable
                    single-line
                    hide-details
                    dense
                  />
                </div>
                <div class="d-flex mt-6 caption">
                  <v-btn
                    text
                    x-small
                    class="caption px-0 align-center"
                    style="margin-top: -1px"
                    color="primary"
                  >
                    체계적인 메이커스페이스 운영시스템
                  </v-btn>
                  <span> 을 이용하여 </span>
                </div>
                <div class="d-flex caption" style="margin-top: -4px">
                  <span> 서비스를 받아볼 수 있는 학교 목록입니다. </span>
                </div>
                <div class="d-flex caption mt-6">
                  <span>선택지에 학교가 없다면 개인으로 가입해주세요.</span>
                </div>
              </v-tab-item>
              <!-- 기업 -->
              <v-tab-item>
                <v-form ref="form3" lazy-validation>
                  <v-row class="px-5">
                    <v-col cols="12" class="pb-1">
                      <v-text-field
                        class="mt-10"
                        label="사업자번호 '-'를 빼고 입력해주세요"
                        :rules="[
                          rule.required,
                          rule.maxLength(10),
                          rule.minLength(10),
                        ]"
                        v-model="form.companyInfo.companyId"
                        required
                        single-line
                        flat
                        dense
                      />
                    </v-col>
                    <v-col cols="6" class="py-1">
                      <v-text-field
                        class="pr-0"
                        label="상호"
                        :rules="[rule.required]"
                        v-model="form.companyInfo.companyName"
                        required
                        single-line
                        flat
                        dense
                      />
                    </v-col>
                    <v-col cols="6" class="py-1">
                      <v-text-field
                        class="pl-2"
                        label="대표자명"
                        :rules="[rule.required]"
                        v-model="form.companyInfo.cheifName"
                        required
                        single-line
                        flat
                        dense
                      />
                    </v-col>
                    <v-col cols="6" class="py-1">
                      <v-text-field
                        class="pr-0"
                        label="업태"
                        v-model="form.companyInfo.class"
                        required
                        single-line
                        flat
                        dense
                      />
                    </v-col>
                    <v-col cols="6" class="py-1">
                      <v-text-field
                        class="pl-2"
                        label="업종"
                        v-model="form.companyInfo.productClass"
                        required
                        single-line
                        flat
                        dense
                      />
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <v-text-field
                        class=""
                        label="세금계산서 수신용 이메일"
                        :rules="[rule.required]"
                        v-model="form.companyInfo.email"
                        required
                        single-line
                        flat
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-tab-item>
            </v-tabs-items>
            <!-- 약관 3종 동의 -->
            <v-row class="px-6 mt-7 caption">
              <div class="d-flex py-1 my-1">
                <v-icon
                  class="mx-2"
                  :color="form.agree.rule ? 'primary' : '#E0E0E0'"
                  @click="
                    form.agree.rule
                      ? (form.agree.rule = false)
                      : (form.agree.rule = true)
                  "
                >
                  mdi-check
                </v-icon>
                <span> [필수] 메이드올 </span>
                <v-btn
                  text
                  x-small
                  class="caption px-1 align-center"
                  color="primary"
                  style="margin-top: -1px"
                  :href="'https://docs.madeall3d.com/terms/rule'"
                  target="_blank"
                >
                  이용약관 동의
                </v-btn>
              </div>
              <div class="d-flex py-1 my-1">
                <v-icon
                  class="mx-2"
                  :color="form.agree.userInfo ? 'primary' : '#E0E0E0'"
                  @click="
                    form.agree.userInfo
                      ? (form.agree.userInfo = false)
                      : (form.agree.userInfo = true)
                  "
                >
                  mdi-check
                </v-icon>
                <span> [필수] 개인정보 수집 및 이용 동의 </span>
                <v-btn
                  text
                  x-small
                  class="caption px-1 align-center"
                  color="primary"
                  style="margin-top: -1px"
                  :href="'https://docs.madeall3d.com/terms/userinfo'"
                  target="_blank"
                >
                  자세히
                </v-btn>
              </div>
              <div class="d-flex py-1 my-1">
                <v-icon
                  class="mx-2"
                  :color="form.agree.marketing ? 'primary' : '#E0E0E0'"
                  @click="
                    form.agree.marketing
                      ? (form.agree.marketing = false)
                      : (form.agree.marketing = true)
                  "
                >
                  mdi-check
                </v-icon>
                <span> [선택] 마케팅 활용 및 광고성 정보 수신 동의 </span>
                <v-btn
                  text
                  x-small
                  class="caption px-1 align-center"
                  color="primary"
                  style="margin-top: -1px"
                  :href="'https://docs.madeall3d.com/terms/marketing'"
                  target="_blank"
                >
                  자세히
                </v-btn>
              </div>
            </v-row>
            <v-card-actions class="mt-7 d-flex justify-center">
              <v-btn class="mx-2 px-5" color="surface" @click="stepIndex = 2">
                이전
              </v-btn>
              <v-btn class="mx-2 px-5" color="primary" @click="save2()">
                완료
              </v-btn>
              <v-dialog v-model="signUpDoneDialog" width="520px" persistent>
                <v-card height="320px" rounded="xl">
                  <v-img
                    src="https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FUsers%2F%EA%B0%80%EC%9E%85%EC%99%84%EB%A3%8C%EC%9D%B4%EB%AF%B8%EC%A7%80.png?alt=media&token=24ece21d-31cb-457e-87fe-a5c3a3fdf1fd"
                  >
                    <div class="d-flex pt-10">
                      <v-icon class="mx-auto" size="100px" color="green">
                        mdi-check-circle
                      </v-icon>
                    </div>
                    <v-card-title class="text-center justify-center">
                      축하합니다. <br />
                      회원가입이 완료되었습니다!
                    </v-card-title>
                    <v-card-actions class="d-flex justify-center mt-3">
                      <v-btn
                        color="primary"
                        class="px-10"
                        @click="signUpFinish()"
                      >
                        홈으로
                      </v-btn>
                    </v-card-actions>
                  </v-img>
                </v-card>
              </v-dialog>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>
<style scoped>
.recaptcha-terms-text {
  font-size: 12px;
  font-weight: 200;
  color: #637282;
}
.v-stepper__header {
  box-shadow: 0px 0px 0px 0px;
  height: 40px;
  justify-content: center;
  border-bottom: #c5c5ca solid 1px;
}
.v-stepper__step {
  width: 33%;
  height: 100%;
  padding: 0px;
  font-size: 14px;
  font-weight: 700;
}
::v-deep .v-stepper__step--complete {
  border-bottom: black solid 2px;
}
::v-deep .v-stepper__step__step {
  display: none;
}
::v-deep .v-stepper__label {
  display: flex !important;
  text-align: center !important;
  align-items: center;
  justify-content: center;
}
.v-label {
  font-size: 14px;
}
.v-tab {
  font-size: 16px;
  padding: 0px;
}
</style>
<script>
import firestorageUtils from "@/utils/firestorage";
import constants from "@/utils/constants";
import Compressor from "compressorjs";
import API from "../../API/auth";
import _ from "lodash";

export default {
  async mounted() {
    this.form = _.cloneDeep(constants.userInterface);
    setTimeout(async () => {
      if (this.$store.getters["auth/user/GET_USER"]) {
        this.loginLoading = true;
        const timer = setInterval(async () => {
          await this.$axios
            .get(
              "users/getData/" + this.$store.getters["auth/user/GET_USER"].uid,
            )
            .then(async (res) => {
              this.form = await _.merge(this.form, res.data);
              this.form.displayName = "";
              clearInterval(timer);
              this.loginLoading = false;
              if (
                this.$store.getters["auth/user/GET_CLAIMS"].disabled === false
              ) {
                this.$toasted.global.notice("이미 가입 완료된 계정입니다");
                this.$router.go(-1);
              }
              if (res.data.emailVerified && res.data.phoneNumber != "") {
                this.stepIndex = 3;
              } else {
                this.stepIndex = 2;
              }
            });
        }, 1000);
      } else {
        this.form = _.merge(this.form, constants.userInterface);
        this.stepIndex = 1;
      }
    }, 500);
  },
  data() {
    return {
      //회원가입 단계 값
      stepIndex: 1,
      loginLoading: false,

      form: {
        //Firebase 회원가입 기본 이메일, 비밀번호 정보
        password1: "",
        show1: false,
        password2: "",
        show2: false,

        //프로필 이미지 업로드
        imgUpload: false,
        imgUploadFile: "",
        imgUploadURL: "",

        userClass: 0,

        schoolInfo: { school: "", studentId: "" },
        companyInfo: {
          companyId: "",
          companyName: "",
          cheifName: "",
          class: "",
          productClass: "",
          email: "",
        },

        agree: { rule: false, userInfo: false, marketing: false },
      },
      saveUserData: {},
      emailCheckLoading: false,
      emailAgain: false,

      deleteUserDialog: false,
      abortBtnLoading: false,

      save1BtnLoading: false,

      schoolList: constants.schoolList,
      signUpDoneDialog: false,
      buttonClicked: 1,

      rule: {
        required: (v) => !!v || "필수 항목입니다.",
        minLength: (length) => (v) =>
          (v && v.length) >= length || `${v && v.length} / ${length}`,
        maxLength: (length) => (v) =>
          (v && v.length) <= length || `${v && v.length} / ${length}`,
        email: (v) => /.+@.+/.test(v) || "이메일 형식에 맞지 않습니다.",
        alpha: (v) =>
          /^[ㄱ-ㅎ|가-힣|a-z|A-Z|]+$/.test(v) || "문자만 입력이 가능합니다.",
        number: (v) => /^[0-9|]+$/.test(v) || "숫자만 입력이 가능합니다.",
      },
    };
  },
  methods: {
    //Google 회원가입
    async signInWithGoogle() {
      this.loginLoading = true;
      API.signInWithGoogle().catch(() => (this.loginLoading = false));
    },
    //Kakao 회원가입
    signInWithKakao() {
      API.signInWithKakao();
    },
    //Naver 회원가입
    signInWithNaver() {
      API.signInWithNaver();
    },
    //Firebase 회원가입
    async createWithEmailAndPassword() {
      if (!this.$refs.form1.validate())
        return this.$toasted.global.error("입력 폼을 올바르게 작성해주세요.");
      this.loginLoading = true;
      API.signUpWithFirebase(this.form.email, this.form.password1).catch(
        () => (this.loginLoading = false),
      );
    },
    //Email 인증 새로고침
    checkEmailVerified() {
      this.emailCheckLoading = true;
      this.$firebase.auth().currentUser.reload();
      this.form.emailVerified = this.$firebase.auth().currentUser.emailVerified;
      setTimeout(() => {
        this.emailCheckLoading = false;
        if (this.form.emailVerified == false) {
          this.emailAgain = true;
        } else {
          this.emailAgain = false;
        }
      }, 500);
    },
    sendEmail() {
      this.emailCheckLoading = true;
      this.$toasted.global.notice("이메일이 새로 발송되었습니다.");
      this.$firebase.auth().currentUser.sendEmailVerification();
      setTimeout(() => {
        this.emailCheckLoading = false;
        this.emailAgain = false;
      }, 300);
    },
    //기본 정보 프로필 이미지 Input
    onFileChanged(e) {
      this.form.imgUploadFile = e.target.files[0];
      if (this.form.imgUploadFile.type.split("/")[0] != "image")
        this.$toasted.global.error("이미지 파일만 등록 가능합니다.");
      this.form.imgUploadURL = URL.createObjectURL(this.form.imgUploadFile);
    },
    //가입취소
    async deleteUser() {
      this.abortBtnLoading = true;
      try {
        this.$firebase.auth().currentUser.delete();
      } catch {
        this.$toasted.global.error(
          "로그인 시간이 만료되어 가입 취소되지 않았습니다. 재로그인 후 시도해주세요.",
        );
      }
      this.form = "";
      this.$store.dispatch("auth/user/SET_LOGOUT", "Login");
    },
    //기본정보 저장
    async save1() {
      if (!this.form.emailVerified)
        return this.$toasted.global.error("이메일 인증을 확인해주세요.");
      if (!this.$refs.form2.validate())
        return this.$toasted.global.error("정보를 모두 작성해주세요.");
      this.save1BtnLoading = true;
      if (this.form.imgUploadFile) {
        const that = this;
        new Compressor(this.form.imgUploadFile, {
          quality: 0.8,
          width: 300,
          convertSize: 20000,
          success(result) {
            that.form.imgUploadFile = result;
          },
        });
        const serverResult = await firestorageUtils.uploadFile({
          file: this.form.imgUploadFile,
          path: `Users/${this.form.uid}/profileImage/${this.form.imgUploadFile.name}`,
        });
        this.form.photoURL = serverResult.downloadURL;
      }
      this.saveUserData = {
        displayName: this.form.displayName,
        emailVerified: this.form.emailVerified,
        photoURL: this.form.photoURL,
        phoneNumber: this.form.phoneNumber,
        userClass: 0,
        level: 3,
        disabled: true,
      };
      if (
        this.$store.getters["auth/user/GET_USER"].displayName !=
          this.form.displayName ||
        this.$store.getters["auth/user/GET_USER"].emailVerified !=
          this.form.emailVerified ||
        this.$store.getters["auth/user/GET_USER"].photoURL !=
          this.form.photoURL ||
        this.$store.getters["auth/user/GET_CLAIMS"].phoneNumber !=
          this.form.phoneNumber
      ) {
        await this.$firebase.auth().currentUser.updateProfile({
          displayName: this.form.displayName,
          photoURL: this.form.photoURL,
          emailVerified: this.form.emailVerified,
        });
        if (
          this.$store.getters["auth/user/GET_CLAIMS"].phoneNumber !=
          this.form.phoneNumber
        ) {
          await this.$axios.patch(
            "users/StoreUpdate/" +
              this.$store.getters["auth/user/GET_USER"].uid,
            this.saveUserData,
          );
        }
        this.$store.dispatch(
          "auth/user/SET_USER",
          this.$firebase.auth().currentUser,
        );
      }
      this.stepIndex++;
      this.save1BtnLoading = false;
    },
    //추가정보 저장
    async save2() {
      if (this.form.userClass == 1) {
        if (this.form.schoolInfo && !this.form.schoolInfo.school)
          return this.$toasted.global.error("학교를 선택하세요.");
        if (!this.form.schoolInfo.studentId)
          return this.$toasted.global.error("학번을 입력하세요.");
      }
      if (this.form.userClass == 2) {
        if (!this.$refs.form3.validate())
          return this.$toasted.global.error("정보를 모두 작성해주세요.");
      }
      if (!this.form.agree.rule)
        return this.$toasted.global.error("이용약관에 동의해주세요.");
      if (!this.form.agree.userInfo)
        return this.$toasted.global.error("개인정보수집에 동의해주세요.");
      this.signUpDoneDialog = true;
      this.saveUserData = {
        disabled: false,
        userClass: this.form.userClass,
        level: 3,
        phoneNumber: this.form.phoneNumber,
        agree: {
          rule: this.form.agree.rule,
          userInfo: this.form.agree.userInfo,
          marketing: this.form.agree.marketing,
        },
        schoolInfo: {
          school: this.form.schoolInfo.school,
          studentId: this.form.schoolInfo.studentId,
        },
        companyInfo: {
          companyId: this.form.companyInfo.companyId,
          companyName: this.form.companyInfo.companyName,
          cheifName: this.form.companyInfo.cheifName,
          class: this.form.companyInfo.class,
          productClass: this.form.companyInfo.productClass,
          email: this.form.companyInfo.email,
        },
      };
      await this.$axios.patch(
        "users/StoreUpdate/" + this.form.uid,
        this.saveUserData,
      );
      await this.$store.dispatch(
        "auth/user/SET_USER",
        this.$firebase.auth().currentUser,
      );
    },
    signUpFinish() {
      this.buttonClicked--;
      if (this.buttonClicked >= 0) {
        this.$axios.patch("channelTalk/upsertProfile", {
          uid: this.form.uid,
          name: this.form.displayName,
          phoneNumber: this.form.phoneNumber,
          email: this.form.email,
          photoURL: this.form.photoURL,
        });
        this.$axios.put("kakaoMsg/signUp", {
          name: this.form.displayName,
          phoneNumber: this.form.phoneNumber,
        });
        this.$router.push("/page/?after_signUp");
      } else {
        console.log("busy processing");
      }
    },
  },
};
</script>
